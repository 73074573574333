import { createRouter, createWebHistory } from "vue-router";

const login = () => import("@/views/auth/login.vue");
const register = () => import("@/views/auth/register.vue");
const confirm = () => import("@/views/auth/confirm.vue");
const recover = () => import("@/views/auth/recover.vue");

import main from "@/Main.vue";
import auth from "@/Auth.vue";
import home from "@/views/home.vue";

const tasks = () => import("@/views/tasks/index.vue");
const profile = () => import("@/views/profile.vue");
const faq = () => import("@/views/faq.vue");

import err404 from "@/views/err404.vue";
import { useStore } from "@/store";

const routes = [
  {
    path: "/auth",
    component: auth,
    meta: { guest: true },
    children: [
      {
        path: "login",
        name: "login",
        component: login,
        meta: { title: "Авторизация" },
      },
      {
        path: "register",
        name: "register",
        component: register,
        meta: { title: "Регистрация" },
      },
      {
        path: "recover",
        name: "recover",
        component: recover,
        meta: { title: "Восстановление доступа" },
      },
      {
        path: "confirm/:code",
        name: "confirm",
        component: confirm,
        meta: { title: "Подтверждение", params: true },
      },
    ],
  },
  {
    path: "/tgbot",
    name: "tgbot",
    component: () => import("@/views/tgbot/index.vue"),
    meta: { title: "RostPoint", auth: true },
  },
  {
    path: "/",
    component: main,
    meta: { auth: true, employer: true },
    children: [
      {
        path: "",
        name: "home",
        component: home,
        meta: { title: "Дашборд", employer: false },
      },
      {
        path: "faq",
        name: "faq",
        component: faq,
        meta: { title: "FAQ", employer: false },
      },
      {
        path: "questions",
        name: "questions",
        component: () => import("@/views/questions/index.vue"),
        meta: { title: "Обращения", employer: false },
      },
      {
        path: "questions/:id",
        name: "question",
        component: () => import("@/views/questions/detail.vue"),
        meta: { title: "Обращения", employer: false },
      },
      {
        path: "profile/:id",
        name: "profile",
        component: profile,
        meta: { title: "Профиль", employer: false, params: true },
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("@/views/settings/index.vue"),
        beforeEnter: (_to, _from, next) => {
          const store = useStore();
          if (!store.employer?.super) {
            return next({ name: "home" });
          }
          next();
        },
        meta: { title: "Настройки" },
      },
      {
        path: "employers",
        name: "employers",
        component: () => import("@/views/employers/index.vue"),
        meta: { can: "employers", title: "Сотрудники" },
      },
      {
        path: "clients",
        name: "clients",
        component: () => import("@/views/clients/index.vue"),
        meta: { can: "clients", title: "Клиенты" },
      },
      {
        path: "leads",
        name: "leads",
        component: () => import("@/views/orders/leads.vue"),
        meta: { can: "leads", title: "Продажи" },
      },
      {
        path: "orders",
        name: "orders",
        component: () => import("@/views/orders/orders.vue"),
        meta: { can: "orders", title: "Заказы" },
      },
      {
        path: "stages",
        name: "stages",
        component: () => import("@/views/orders/stages.vue"),
        meta: { can: "orders", title: "Информация" },
      },
      {
        path: "tasks",
        name: "tasks",
        component: tasks,
        meta: { can: "tasks", title: "Задачи" },
      },
      {
        path: "meetings",
        name: "meetings",
        component: () => import("@/views/meetings/index.vue"),
        meta: { can: "meetings", title: "Совещания" },
      },
      {
        path: "storage",
        name: "storage",
        component: () => import("@/views/storage/index.vue"),
        meta: { can: "storage", title: "Диск" },
      },
      {
        path: "reports",
        name: "reports",
        component: () => import("@/views/reports/index.vue"),
        meta: { can: "reports", title: "Аналитика" },
      },
      {
        path: "motivation",
        name: "motivation",
        component: () => import("@/views/motivation/index.vue"),
        meta: { can: "motivation", title: "Мотивация" },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: err404,
        meta: { title: "404" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const store = useStore();
  if (store.token && !store.auth) {
    await store.loadData();
  }
  const { can: metaCan } = to.meta;
  if (to.meta.auth && !store.auth) {
    if (window.Telegram) {
      await store.tgLogin();
    } else {
      next({ name: "login" });
    }
  } else if (
    (to.meta.guest && store.auth) ||
    (metaCan && !store.can(metaCan))
  ) {
    next({ name: "home" });
  } else if (!store.employer && to.meta.employer) {
    next({ name: "home" });
  } else {
    document.title = to.meta.title;
    next();
  }
});

export default router;
