<template>
  <q-header elevated>
    <q-toolbar>
      <q-btn
        dense
        flat
        no-caps
        @click="store.toggleUi('sidebar')"
        icon="menu"
        class="q-mr-sm q-ml-sm">
        <q-tooltip>Убрать меню</q-tooltip>
      </q-btn>
      <OrdersSelector
        label="Поиск сделок"
        hide-dropdown-icon
        @update:modelValue="$bus.emit('orders/showDialog', $event.id)"
        v-if="store.can('leads') || store.can('orders')"></OrdersSelector>
      <q-space />
      <q-btn
        flat
        noCaps
        :label="`Демо ${store.user.demo} дн`"
        color="positive"
        v-if="store.user.demo && store.employer?.super"
        :to="store.employer?.super ? '/settings' : null"></q-btn>
      <q-btn
        flat
        noCaps
        label="Доступ ограничен"
        color="amber"
        v-if="store.employer?.company?.readonly"></q-btn>
      <Points v-if="store.employer"></Points>
      <Clock></Clock>
      <q-separator vertical inset class="q-mx-sm" />
      <VoiceChat v-if="store.user && store.mode === 'test'" />
      <q-btn
        dense
        flat
        no-caps
        color="positive"
        :icon="!store.user?.ui_settings.dark ? 'light_mode' : 'mode_night'"
        @click="toggleTheme" />
      <q-btn
        dense
        flat
        no-caps
        color="amber"
        icon="notifications"
        v-if="store.employer"
        @click="$bus.emit('notifications/showDialog')">
        <q-badge floating color="negative" rounded v-if="store.unread" />
      </q-btn>
      <q-btn
        dense
        flat
        no-caps
        color="positive"
        icon="people"
        v-if="store.employer">
        <q-badge floating color="primary" rounded>
          {{ store.online.length }}
        </q-badge>
        <q-menu flat style="min-width: 300px">
          <EmployersList
            :employers="store.online"
            :profile="true"
            label="Сотрудники в сети"></EmployersList>
        </q-menu>
      </q-btn>
      <q-btn
        dense
        flat
        no-caps
        color="positive"
        @click="$q.fullscreen.toggle()"
        :icon="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'" />
      <q-separator vertical inset class="q-mx-sm" />
      <q-btn dense flat no-caps>
        <Avatar :employer="store.employer || store.user" />
        <q-menu>
          <q-list bordered padding dense>
            <q-item
              clickable
              v-close-popup
              :to="{ name: 'profile', params: { id: store.employer?.id } }">
              <q-item-section avatar>
                <q-icon name="account_circle"></q-icon>
              </q-item-section>
              <q-item-section>Профиль</q-item-section>
            </q-item>
            <q-item clickable :to="{ name: 'faq' }" v-close-popup>
              <q-item-section avatar>
                <q-icon name="quiz"></q-icon>
              </q-item-section>
              <q-item-section>FAQ</q-item-section>
            </q-item>
            <q-item clickable :to="{ name: 'questions' }" v-close-popup>
              <q-item-section avatar>
                <q-icon name="help"></q-icon>
              </q-item-section>
              <q-item-section>Поддержка</q-item-section>
            </q-item>
            <q-item
              clickable
              @click="$bus.emit('settings/showDialog')"
              v-close-popup>
              <q-item-section avatar>
                <q-icon name="settings"></q-icon>
              </q-item-section>
              <q-item-section>Настройки</q-item-section>
            </q-item>
            <q-item clickable @click="logout" v-close-popup>
              <q-item-section avatar>
                <q-icon name="power_settings_new"></q-icon>
              </q-item-section>
              <q-item-section>Выход</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </q-toolbar>
  </q-header>
</template>
<script setup>
import EmployersList from "@/components/employersList.vue";
import Avatar from "@/components/employers/avatar.vue";
import OrdersSelector from "@/components/selectors/orders-selector.vue";
import Clock from "@/components/layout/clock.vue";
import Points from "@/components/layout/points.vue";
import { useStore } from "@/store";
import { inject } from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
import VoiceChat from "../voiceChat.vue";

const store = useStore();
const $bus = inject("bus");
const $q = useQuasar();
const router = useRouter();

const logout = async () => {
  await store.logout();
  router.push({ name: "login" });
};

const toggleTheme = () => {
  store.toggleUi("dark");
  $q.dark.toggle();
};
</script>
