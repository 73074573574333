<template>
  <q-select
    dense
    standout
    :label="label"
    :options="store[model]"
    :model-value="props.modelValue"
    @update:model-value="emit('update:modelValue', $event)"
    option-label="name"
    option-value="id"
    options-dense
    :multiple="multiple"
    :map-options="mapOptions">
    <template v-slot:before-options v-if="multiple">
      <q-item clickable @click="toggleAll" dense>
        <q-item-section>Выбрать все</q-item-section>
      </q-item>
    </template>
    <template v-slot:append v-if="editable">
      <q-btn
        dense
        flat
        icon="settings"
        size="sm"
        @click.stop="$bus.emit('books/showDialog', model)"></q-btn>
    </template>
  </q-select>
</template>

<script setup>
import { useStore } from "@/store";
import { defineProps, toRefs, defineEmits, inject } from "vue";

const props = defineProps({
  modelValue: String,
  filterable: Boolean,
  model: String,
  label: String,
  editable: Boolean,
  multiple: Boolean,
  mapOptions: Boolean,
});
const { label, model, editable, mapOptions } = toRefs(props);
const emit = defineEmits(["update:modelValue"]);
const store = useStore();
const $bus = inject("bus");

const toggleAll = () => {
  const items = store[model.value].slice();
  const result = props.mapOptions ? items.map((item) => item.id) : items;
  emit("update:modelValue", result);
};
</script>
