<template>
  <div>
    <RouterView />
    <q-dialog v-model="termsDialog" @before-hide="audio.pause()">
      <q-card>
        <q-card-section>
          <div class="text-h5">Правила и соглашения</div>
        </q-card-section>
        <q-card-section> </q-card-section>
        <q-card-actions align="right">
          <q-btn
            dense
            color="positive"
            no-caps
            label="Понятно"
            @click="termsDialog = false" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script setup>
import { inject, onMounted, ref } from "vue";
import { play } from "./helpers";
import { axios } from "@/services";

const termsDialog = ref(false);
const terms = ref("");
const $bus = inject("bus");

let audio = null;

onMounted(() => {
  $bus.on("open-terms", async () => {
    try {
      if (!terms.value) {
        const { data } = await axios.get("terms");
        terms.value = data;
      }
      audio = play("_t");
      termsDialog.value = true;
    } catch (e) {
      //
    }
  });
});
</script>
